import axios from "axios";
import {creds} from "@/states/Cred";

export default class ReviewFetchService {
    getReviewFetchList() {
        return axios.get(`${process.env.VUE_APP_API_URL}/reviewfetch`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    updateReviewFetch(reviewFetch) {
        return axios.put(`${process.env.VUE_APP_API_URL}/reviewfetch`,
            reviewFetch,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    createExport(reviewFetch) {
        return axios.post(`${process.env.VUE_APP_API_URL}/export`,
            reviewFetch,
            {headers: {"Authorization": `Bearer ${creds.token}`}, responseType: "arraybuffer"})
            .then(res => res)
            .catch(err => err.response)
    }
}
