<template>
  <Toast/>
  <DataTable :value="reviewFetchList" tableStyle="min-width: 50rem">
    <Column field="account.accountName" header="Account Name"></Column>
    <Column field="app.packageName" header="Package Name"></Column>
    <Column field="app.appName" header="App Name"></Column>
    <Column header="Schedule In Seconds">
      <template #body="slotProps">
        <InputText id="scheduleInSeconds" type="number" style="width: 70px"
                   v-model="slotProps.data.scheduleInSeconds" @change="onChangeReviewFetch(slotProps.data)"/>
      </template>
    </Column>
    <Column header="Enabled">
      <template #body="slotProps">
        <ToggleButton v-model="slotProps.data.enabled" @change="onChangeReviewFetch(slotProps.data)" onLabel="Enabled"
                      offLabel="Disabled"
                      onIcon="pi pi-check" offIcon="pi pi-times" class="w-full sm:w-10rem"/>
      </template>
    </Column>

    <Column header="Export">
      <template #body="slotProps">
        <Button label="Export" icon="pi pi-folder-open" @click="openExportDialog(slotProps.data)"/>
      </template>
    </Column>
  </DataTable>

  <Dialog v-model:visible="exportDialog" :style="{width: '450px'}" header="Export Comments" :modal="true"
          class="p-fluid">
    <div class="col-12 md:col-12">
      <div class="field">
        <Calendar v-model="selectedDates" dateFormat="dd/mm/yy" selectionMode="range" :manualInput="false" inline/>
      </div>
    </div>
    <div class="col-12 md:col-12">
      <div class="field">
        <label for="Date From">Date From</label>
        <InputText type="text" v-model="selectedDates[0]" disabled/>
      </div>
      <div class="field">
        <label for="Date From">Date To</label>
        <InputText type="text" v-model="selectedDates[1]" disabled/>
      </div>
    </div>

    <template #footer>
      <Button icon="pi pi-check" label="Create" @click="createExport()"/>

      <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em"
              @click="closeExportDialog()"/>
    </template>
  </Dialog>
</template>

<script>
import {lastSearchCriteria} from "@/states/State";
import ReviewFetchService from "@/service/ReviewFetchService";

export default {
  name: "ReviewFetch",
  created() {
    this.reviewFetchService = new ReviewFetchService();
  },

  data() {
    return {
      lastSearchCriteria,
      reviewFetchService: null,
      reviewFetchList: [],
      selectedDates: [],
      exportDialog: false,
      selectedReviewFetch: null
    }
  },

  mounted() {
    this.refreshReviewFetchList()
  },

  methods: {
    openExportDialog(reviewFetch) {
      this.selectedReviewFetch = reviewFetch;
      this.exportDialog = true;
    },
    closeExportDialog() {
      this.selectedReviewFetch = null;
      this.exportDialog = false;
    },

    createExport() {
      if (!this.selectedReviewFetch) {
        return
      }

      const exportRequest = {
        dateFrom: this.selectedDates[0],
        dateTo: this.selectedDates[1],
        packageName: this.selectedReviewFetch.app.packageName
      }

      this.reviewFetchService.createExport(exportRequest)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Update Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              const url = window.URL.createObjectURL(new Blob([res.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'reviews.xlsx')
              document.body.appendChild(link)
              link.click()
            }
          })

    },

    onChangeReviewFetch(reviewFetch) {
      this.reviewFetchService.updateReviewFetch(reviewFetch)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Update Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Successfully updated',
                life: 3000
              });
            }
          })
    },

    refreshReviewFetchList() {
      this.reviewFetchService.getReviewFetchList()
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'refresh review fetch Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.reviewFetchList = res.data
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
